/* =================== HEADER FULL =================== */
jQuery(document).ready(function($){

  /*DESCE E SOBE BARRA DE BUSCA*/
  $('.segura-menu-mobile-pesquisa').on("click", function () {
    $('.segura-menu-mobile-barra-pesquisa').slideToggle();
  });
  /*DESCE E SOBE BARRA DE BUSCA*/
  
  const header = $('.header-full');
  if (header.length > 0) {
    function toggleHeaderFull() {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > 100) {
        header.addClass('active');
      } else {
        header.removeClass('active');
      }
    }
    toggleHeaderFull();
    $(window).on('scroll', debounce(function(){
      toggleHeaderFull();        
    }, 10));
  }
});
